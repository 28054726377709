body {
    background-color: rgb(240, 240, 240);
}

.qslate p,
.qslate li,
.qslate blockquote {
    font-size: 1em;
	margin: 0 0 1em 0;
}

.qslate li {
    margin-bottom: 0;
}

.qslate ol,
.qslate ul {
    margin: 10px 0px;
}

.qslate img {
    margin-bottom: 15px;
    /* margin-top: -1rem; */
}

.qslate h1, .qslate h2, .qslate h3, .qslate h4, .qslate h5, .qslate h6 { 
	font-family: inherit;
	margin: 0 0 1em 0;
	line-height: 1.2;
}

.qslate h1{
	font-size: 2.4em;
}

.qslate h2{
	font-size: 2em;
}

.qslate h3{
	font-size: 1.7em;
}

.qslate h4{
	font-size: 1.4em;
}

.qslate h5{
	font-size: 1.2em;
}

.qslate h6{
	font-size: 1em;
}